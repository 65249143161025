.desc_div .text p {
  font-size: 18px;
  white-space: normal;
  color: #616161;
  word-spacing: 4px;
  word-break: break-all;
}







linkcss {
     padding: 10px;
     display: block;
     padding-left: 50px;
     text-decoration: none;
     position: relative;
     cursor: pointer;
     color: #818BA0;
}
.ext-css{
  display: flex;
  justify-content: center;
  
  align-items: center;
}
.video-react {
  background-color: #0000;
}
.txtClassUpper{
  text-transform: capitalize;

}
.select.form-control option {
  text-transform: capitalize;
}
.heih93{
  height: 93px;
}

.display-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.management_edit iframe {
  width: 100%;
}
/* For Firefox */
input[type='number'] {
  -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.example {
  border-left: 1px solid #999999;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-top: 1px solid #999999;
  border-radius: 10px;
  margin: 0px;
  padding: 20px;
}

.filter_dropdown_parent {
    display: flex;
    align-items: center;
}
.filter_dropdown_option {
  height: 15px;
  width: 15px;
  background: pink;
  border-radius: 100px;
}
.item {
  font-family: sans-serif;
  border-top: 1px solid #ffffff;
  background-color: #eeeeee;
  border-bottom: 1px solid #999999;
  border-radius: 6px;
  margin-bottom: 4px;
  padding: 10px;
  padding-left: 8px;
  opacity: 1;
}

.item .title {
  font-weight: bold;
  font-size: 125%;
  margin: 0;
  margin-bottom: 0.3rem;
}

.item .body {
  margin: 0;
  margin-bottom: 0.3rem;
}

.item .small {
  font-size: 66%;
  text-align: right;
}
/* ----------------left right back arrow---------- */
.cm_arrow svg {
  width: 16px;
  height: 16px;
}
.dragged .item {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 4px 20px #666666;
}

.dl-item.floating {
  width: 85%;
}


.impFields{
  color:red
}
.muti_media{
float: left;
margin-left: 12px;
margin-top: 6px;
}

.headingH6{
  text-transform: uppercase
}
.headingH6 a {
  color: #000;
  text-decoration: none;
}
.textContent{
    font-size: 20px;
    color: #717171;
    margin: 0;
}

.fa.fa-window-minimize {
  color: #888;
}

.mT0{
  margin-top:0px !important;
}
.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.sidenav_categories a svg {
  max-width: 19px;
    margin-right: 9px;
    max-height: 16px;
    margin-top: -5px;
}
body {
  font-family: 'Cabin', sans-serif;
  white-space: nowrap;
  background: #FCFDFF;
}
body,
.btn,
.form-control {
  font-size: 14px;
}
.mR-20{
  margin-right: 20px;
}
.ws{
  white-space: normal;
}
.upload_fileimg{
  width:150px;
  height:100px;
   object-fit: contain;
}
.link-btn ,.link-btn:hover{
  color: #888;
  text-decoration: none;
}
/* 
.upload-file {
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  opacity: 0;
} */

.buttonColorPicker { 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.cur_pointer{
  cursor: pointer;
}
.colorDIv{
  background: none;
  padding: 0px;
  border: none !important;
  height: 48px;
  border-radius: 10px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  width: 100%;
  z-index: 9999;
  background-color: #ffffff78;
  top: 0;
}
.sort_icon {
  margin-left: 6px;
  color: #27a3dc;
}
.more_dots{
  float: right;
    margin-right: 10px;
}
.df-end{
  display: flex;
    align-items: flex-end;
}
.cm_active{
  color:#44DB5E;
  font-weight: 600;
  font-size: 14px;
}
.cm_alert_danger{
  color: red
}
.form-control.is-invalid{
  background-image:none !important
}
.position_rel{
  position: relative;
}
.btn-primary {
  color: #fff;
  background-color: #27a3dc;
  border-color: #26a2db;
  border-radius: 0;
}
.btn-primary:hover{
  color: #fff;
    background-color: #27a3dc;
    border-color: #27a3dc;
}
/* .btn-b{} */
.fixed_footer{
  position: fixed;
    bottom: 0;
    color: #fff;
}
.login_body_wrapper {
  height: 100vh;
  min-height: 500px;
}
.auth_body_wrapper {
  background: #1ba0da;
  padding: 90px 0;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.logintext{
  white-space: initial;
    color: #818BA0;
    font-size: 13px;
}
.auth_body_wrapper .container {
  background: #fff;
  border-radius: 4px;
  height: calc(100vh - 180px);
  min-height: 500px;
  box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.3);  
  -moz-box-shadow:   0px 10px 50px 0px rgba(0,0,0,0.3);
}
.cm_login_form h5 {
  font-weight: 600;
}
/* -------------------- css for input field animation start------------------------- */
.field {
  position: relative;
  margin-bottom: 15px;
}

.field label::before {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 15px;
  line-height: 40px;
  font-size: 14px;
  color: #777;
  transition: 300ms all;
}

.field input {
  width: 100%;
  line-height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  color: #222;
  border: transparent;
    border-bottom: 1px solid #ccc;
}


.field input:focus {
  outline: 0;
  border-color: blue;
}

.field input:valid + label::before {
  line-height: 20px;
  font-size: 12px;
  top: -10px;
  background: #fff;
  padding: 0 6px;
  left: 9px;
  content: attr(data-title);
}

.field input:focus + label::before {
  line-height: 20px;
  font-size: 12px;
  top: -10px;
  background: #fff;
  color: blue;
  padding: 0 6px;
  left: 9px;
}
/* -------------------- css for input field animation end------------------------- */
.login_bg {
  height: 75vh;
  margin-top: 3vh;
  background: transparent;
  box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.3);
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.login_body_wrapper .container {
  background: transparent;
  box-shadow: none;
}
.login_from_bg {
  height: 81vh;
  background: #fff;
  box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.3);  
  -moz-box-shadow:   0px 0px 21px 0px rgba(0,0,0,0.3);
}
.d-flex {
  display: flex!important;
}
.justify-content-between {
  justify-content: space-between!important;
  align-items: center;
}
.form-group.d-flex.justify-content-between a {
  color: #495057;
  text-decoration: none;
}
.login_background {
  background: #24a2db;
  position: relative;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.btn-blue{
border-radius: 4px;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
  -webkit-box-shadow:  0 0 0 0.2rem transparent;  
  -moz-box-shadow:    0 0 0 0.2rem transparent;
}
.btn-default{
  border-radius: 4px;
  background: #818BA0;
  color: #fff;
  border-color: #818BA0;
}
.btn-pink{
  border-radius: 4px;
    background: #FF1F70;
    color: #fff;
    border-color: #FF1F70;
}
.btn-sm {
  padding: 5px 10px !important;
  font-size: 12px;
  line-height: 1.5;
  border-radius: .2rem;
  min-width: 60px;
}
.cm_login_form {
  width: 100%;
  padding: 0 15%;
}
ul.profile_menu {
  margin: 0 0px;
  padding: 0;
  list-style: none;
}

.login_logo {
  margin-bottom: 20px;
}

.create_acc_btn {
  margin-top: 20vh;
}

.btn {
  padding: 8px 30px;
}
.btn-border , .btn-border:hover {
  border: 1px solid #27a3dc;
  color: #27a3dc;
}
.btn-border svg {
  width: 16px;
  margin-right: 5px;
}
.cm_from_group {
  background: #fdfdfd;
  box-shadow: 0px 0px 4px #e1e1e1ee;
  -webkit-box-shadow: 0px 0px 4px #e1e1e1ee;  
  -moz-box-shadow:   0px 0px 4px #e1e1e1ee;
  padding: 10px 20px;
  border-left: 3px solid #21a0db;
  margin-bottom: 2px;
}
.cm_from_group label {
  margin-bottom: 0;
  color: #818BA0;
}
.cm_from_group .form-control {
  font-size: 14px;
  background: transparent;
  border: none;
  padding: 0;
  line-height: .8;
  height: auto;
  margin: 2px 0px;
  color: #818BA0;
}
.form-control:focus{
  outline: none;
  box-shadow: none;
}
.form-control {
  height: calc(2.1em + .75rem + 2px);
}
.Back_sign{
  color:#818BA0
}
/* .form-group {
  margin-bottom: 25px;
  position: relative;
}

.form-control.icon_form {
  height: calc(1.5em + .75rem + 10px);
  padding-left: 50px;
} */
.primary_menu {
  display: inline-block;
  width: calc(100% - 52px);
  vertical-align: middle;
}

.header_dropdown {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}


.no_btn {
  background: transparent !important;
  border: none !important;
  outline: none;
  color: #000;
  padding: 0;
  font-size: 16px;
}
.auth_content {
  color: #fff;
  padding-left: 5%;
  padding-bottom: 3%;
}

.input_icon {
  position: absolute;
  width: 42px;
  height: 42px;
  border-right: 1px solid #ced4da;
  left: 0;
  top: 0;
}

span.input_icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
}

.forgot_pass {
  color: #d4d6db;
  position: absolute;
  top: 20px;
  right: 10px;
}
.create_acc_btn_icon {
  display: inline-block;
  width: 7px;
  position: relative;
  left: 0px;
  animation-name: slide_right;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
/* -----------------------sidenavbar---------------------- */
.sidenav {
  height: 100%;
    width: 300px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #33385D;
    overflow-x: hidden;
    padding-top: 20px;
    text-align: center;
   
}

.sidenav_categories{
  padding-left: 20px;
}
.sidenav img {
  margin: 32px 0px;
}
.sidenav p {
  color: #BEBEBE;
    font-weight: 600;
    margin: 20px 0 10px;
    padding: 0 16px;
}
.sidenav a {
  padding: 14px 2px 14px 26px;
  text-decoration: none;
  font-size: 17px;
  color: #ffffff;
  display: block;
  text-align: left;
}
.sidenav a .fa {
  margin-right: 12px;
}
.sidenav a.active {
  background: #20233C;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
}
.sidenav a.active .fa {
  color: #6ECEEA;
}
.sidebarDetails span {
  color: #000;
}
.sidenav a:hover {
  text-decoration: none
}
.main-body{
  background: #FCFDFF;
    margin-left: 300px;
    min-height: 100vh;
    padding: 30px 30px 30px 30px;
    position: relative;
}

.cm_center_text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #717171;
  margin: 0;
}

/* ----------------------------------table--------------------------------------- */
.table thead th{
  color: #818BA0;
  font-size: 13px;
  border:none;
  text-align: left;
}
.table {
  border-collapse: separate;
  border-spacing: 0 14px;
}
.table tbody tr td {
  border: none;
  text-align: left;
}
.table tbody tr{
   box-shadow: 0px 2px 5px #e5e5e5;
  -webkit-box-shadow:0px 2px 5px #e5e5e5 ;  
  -moz-box-shadow:  0px 2px 5px #e5e5e5 ; 
  background:#fff;
   
   /* background: url('./assets/img/border-bottom.png');
  background-position: center bottom;
  background-repeat: repeat-x;  */
 
}
.safari .table tbody tr td {
  background-image: url(./assets/img/border-bottom.png);
  background-position: center bottom;
  background-repeat: repeat-x;
  padding-bottom: 21px;
  padding-top: 0px;
}
.table td{
  font-size: 13px;
}
/* .table-condensed tr th:nth-child(7) {
  min-width: 220px;
} */
/* ----------------------------------tag-------------------------------------- */
.color_circle{
  width:18px;
  height:18px;
  border-radius: 40px;
 
}
.body_header h6 {
  font-weight: 900;
  margin-bottom: 46px;
}
.cm_eyedroper {
  font-size: 20px;
  position: relative;
  top: -15px;
  left: 60px;
  cursor: pointer;
}
.display-b{
  display: block;
}
/* -----------------------popup------------------------------- */
.close {
  opacity: 1;
}
.modal-header .close span {
  /* background: #000; */
  padding: 0px;
  position: absolute;
  border-radius: 20px;
  height: 26px;
  width: 24px;
  color: #000;
  right: 13px;
  opacity: 1;
  cursor: pointer;
  outline: none;
}
.modal-content {
  border-radius: 0;
  border: none;
}
.modal-header{
  border:none
}
.modal-body {
  padding: 0rem 3.5rem 2rem;
}
.modal-header h6 {
  padding-left: 2.5rem;
  padding-top: 7px;
  margin-bottom: 0px !important;
}
.modal-header .close {
padding: 0rem 0rem; 
margin: 0.2rem -0rem -0rem auto;
}
.custom_modal h6 {
  color: #818BA0;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 30px;
}
.custom_modal label{
    color: #000;
    font-weight: 600;
    font-size: 13px;
}
/* input[type="color"] {
  background: none;
  padding: 0px;
  border: none !important;
  height: 48px;
  border-radius: 10px;
} */
/* ---------------------------------folder structure---------------------- */
.folder_parent {
  display: flex;
  flex-wrap: wrap;
  /* margin-top:30px; */
}
.folder_container {
  border: 1px solid #E7E7EA;
  margin: 6px;
  width: 185px;
  height: 205px;
  position: relative;
  text-align: center;
  cursor: pointer;
  background: #fff;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 12px;
}
.folder_container .center {
  width: 80%;
}
.center .img-fluid {
  width: 100%;
  height: 100px;
  padding: 0px 12px;
  object-fit: cover;
}
.body_header.folder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 4px;
  margin-bottom: 30px;
}

.body_header.folder h6 {
  margin-bottom: 0;
}
.folder_container p {
  margin-bottom: 0px;
  padding: 12px 0px;
  font-weight: 600;
}
.page-item {
  background: #0D9CD8;
  border: none;
  border-radius: 6px;
  padding: 6px 13px;
  margin: 0px 12px;
  color: #fff;
}
.pagination {
  align-items: center;
}
.pagination p {
  margin-bottom: 0px;
  color:#818BA0;
  font-weight: 600;
}
.P1016{
  padding: 8px 16px;
}
.counter_detail{
  display: flex;
 flex-wrap: wrap;
 position: relative;
 align-items: center;
}
.counter_parent{
  display: flex;
  background: #fff;
  box-shadow: 0px 2px 4px #f1f1f1;
  -webkit-box-shadow: 0px 2px 4px #f1f1f1;  
  -moz-box-shadow:   0px 2px 4px #f1f1f1;
  width: 250px;
  padding: 15px 34px;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
    margin-right: 26px;
}
.counter_parent.active {
  box-shadow: 0px 3px 6px #27a3dc8a;
  -webkit-box-shadow: 0px 3px 6px #27a3dc8a;  
  -moz-box-shadow:   0px 3px 6px #27a3dc8a;
}
.counter_parent:hover{
  background: #27a3dc63;

}



.counter_parent h4:first-child {
  color: #818BA0;
  font-weight: 600;
  letter-spacing: 2px;
}
.counter_parent h4:nth-child(2){
  color: #54C7FC;
  font-weight: 600;
  letter-spacing: 2px;
}
.yellow{
  color: #FFCD00 !important;
}
.green{
  color:#44DB5E !important;
}
/* ---------------------active dropdown-------------------------- */
ul.active_menu {
  margin-bottom: 0px;
}
ul.active_menu li {
  list-style-type: none;
  font-size: 14px;
  color: #000;
  cursor: pointer;
}
ul.active_menu li a , ul.active_menu li a:hover{
  color: #000000;
  text-decoration: none;
}
.active_ddl .dropdown-menu.show{
  box-shadow: 0px 3px 4px #e3e3e3;
  -webkit-box-shadow: 0px 3px 4px #e3e3e3;  
  -moz-box-shadow:   0px 3px 4px #e3e3e3;
  border: none;
}

/* --------------------------old--------------------------- */
header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  border-bottom: 1px solid #E5E5E5;
  padding: 10px 75px;
  z-index: 999;
  background: #fff;
}

.logo {
  display: inline-block;
  width: 160px;
  vertical-align: middle;
}



ul.profile_menu li a,
ul.profile_menu li span {
  padding: 10px;
  display: block;
  padding-left: 0px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  color: #818BA0;
}
ul.profile_menu li a{
    padding-left: 0px;
    color: #000000;
    font-size: 15px;
    border-bottom: 1px solid #f1f1f1;
}

ul.profile_menu li span {
  color: #1e8bff;
}

ul.profile_menu li a:hover,
ul.profile_menu li span:hover {
  background: #ffffff;
    color: #070707;
}

ul.profile_menu li svg {
  position: absolute;
  height: 18px;
  max-width: 16px;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  fill: #818BA0;
}

ul.profile_menu li span svg {
  fill: #0d9cd8;
}

ul.profile_menu li a:hover svg,
ul.profile_menu li span:hover svg {
  fill: #fff !important;
}
.no_btn:hover {
  color: #000;
  background-color: transparent;
  border: 0;
}

.no_btn:after {
  display: none;
}
.no_btn:focus,
.no_btn:not(:disabled):not(.disabled).active:focus, .no_btn:not(:disabled):not(.disabled):active:focus, .show>.no_btn.dropdown-toggle:focus {
  box-shadow: none;
}

.no_btn:not(:disabled):not(.disabled).active, .no_btn:not(:disabled):not(.disabled):active, .show>.no_btn.dropdown-toggle {
  color: #000;
}

span.menu_icon {
  display: inline-block;
  width: 18px;
}

span.down_angle {
  display: inline-block;
  width: 7px;
  transform: rotate(90deg);
  margin-left: 10px;
}

.header_dropdown_btn #dropdown-basic img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 16px;
  border: 2px solid white;
  margin: 0;
  /* box-shadow: 0px 2px 8px #8e8e8e;
  -webkit-box-shadow: 0px 2px 8px #8e8e8e;  
  -moz-box-shadow: 0px 2px 8px #8e8e8e;
  box-shadow: 0px 2px 8px #8e8e8e; */
}
button.no_btn.dropdown-toggle.btn.btn-primary.profile_btn {
  box-shadow: 0 2px 8px #8e8e8e;
  -webkit-box-shadow: 0px 2px 8px #8e8e8e;  
  -moz-box-shadow: 0px 2px 8px #8e8e8e;
  border-radius: 100px;
}
.more_dropdown_btn{
  text-align: right;
    margin-right: 2px;
    width: 100%
}
.primary_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}

.primary_menu ul li {
  display: inline-block;
  margin-right: 50px;
}

.primary_menu ul li a {
  color: #000;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
}


.min_height {
  min-height: 61px;
}

.header_dropdown .dropdown-menu {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);  
  -moz-box-shadow:  0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  transform: none !important;
  left: auto !important;
  right: 0;
  top: 47px !important;
  border: 0;
  border-radius: 0;
  width: 220px;
  padding: 0px 15px 4px;
}

.header_profile {
  position: relative;
  padding-left: 70px;
}

span.header_pro_img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

span.header_pro_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}

span.upload_pro_img input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 99;
}

span.upload_pro_img {
  position: absolute;
  width: 100%;
  height: 24px;
  background: rgba(0,0,0,0.6);
  left: 0;
  bottom: 0;
}

span.upload_pro_img svg {
  position: absolute;
  width: 17px;
  left: 50%;
  transform: translateX(-50%);
  top: 3px;
}

.header_profile h4 {
  font-size: 22px;
  margin: 0;
}
.header_profile p {
  margin: 0;
}

.header_profile p a {
  color: #818BA0;
  text-decoration: none;
}











.body_container {
  padding: 0 75px;
}

.cm_breadcumd {
  padding: 20px 0;
}

.cm_breadcumd p {
  margin: 0;
  color: #818BA0;
  font-size: 18px;
  font-weight: bold;
}


.project_card {
  border: 1px solid #E1E2E7;
  border-radius: 4px;
  margin-bottom: 40px;
  box-shadow: 8px 3px 12px 0px grey;
  -webkit-box-shadow: 8px 3px 12px 0px grey;  
  -moz-box-shadow:   8px 3px 12px 0px grey;
  position: relative;
  cursor: pointer;
}

.cm_card_padding {
  padding: 20px 15px;
}

.project_card h3 {
  color: #0D9CD8;
  font-size: 24px;
  font-weight: bold;
}

.project_card h5 {
  color: #818BA0;
  font-size: 16px;
  margin-bottom: 15px;
}

.project_card_img img {
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.readmore {
  text-align: right;
}

.readmore a {
  font-weight: bold;
  color: #0D9CD8;
}

.copyright {
  padding: 30px 75px;
  text-align: center;
  padding-top: 0;
}

span.arrow_icon {
  display: inline-block;
  width: 14px;
  margin: 0 10px;
}

.cm_col_2 {
  flex: 0 0 140px;
  max-width: 140px;
}

.cm_col_4 {
  flex: 0 0 320px;
  max-width: 320px;
}

.cm_box_view .form-control, .cm_box_view .btn {
  border-radius: 0px;
}

.cm_box_view p {
  margin: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.profile_wrapper,
.change_password {
  min-height: calc(100vh - 180px);
}




.header_profile_big {
  padding-left: 140px;
  min-height: 120px;
  padding-top: 30px;
  display: inline-block;
  max-width: 450px;
  width: 100%;
}

span.header_pro_img_big {
  width: 120px;
  height: 120px;
}

span.header_pro_img_big img {
  width: 120px;
  height: 120px;
}

.header_profile_big h4 {
  font-size: 26px;
  margin: 0;
}

.profile_btn {
  display: inline-block;
}


.profile_btn .btn {
  background: #0D9CD8;
  border-color: #0D9CD8;
  border-radius: 0;
  padding: 10px 40px;
  min-width: 146px;
}

.cm_tabs_wrapper .nav-tabs a {
  color: #818BA0;
  border: 0;
  border-radius: 0;
  padding: 14px 15px;
  display: inline-block;
  margin-right: 1px;
}

.cm_tabs_wrapper .nav-tabs a:hover,
.cm_tabs_wrapper .nav-tabs a.active {
  color: #fff;
  background-color: #0D9CD8;
  border: none;
}

.cm_tabs_wrapper .nav-tabs a svg {
  height: 18px;
  margin-right: 5px;
  display: inline-block;
}
.cm_tabs_wrapper .nav-tabs a svg path {
  fill: #818BA0;
}

.cm_tabs_wrapper .nav-tabs a:hover svg path,
.cm_tabs_wrapper .nav-tabs a.active svg path {
  fill: #fff;
}

.cm_tabs_wrapper .nav-tabs {
  border-bottom: 1px solid #E1E2E7;
  border-top: 1px solid #E1E2E7;
  padding-left: 75px;
}

.cm_tabs_wrapper {
  position: relative;
  padding-right: 350px;
}

.about_project_wrapper {
  position: absolute;
  right: 0;
  width: 350px;
  top: 0;
  border: 1px solid #E1E2E7;
  border-right: 0;
}

.cm_breadcumd.cm_space {
  padding: 20px 75px;
}

.cm_tabs_wrapper .tab-content {
  min-height: calc(100vh - 229px);
}

.tabs_space_padding {
  padding: 20px;
  padding-left: 75px;
}

.search_wrapper {
  position: relative;
  padding-right: 265px;
  min-height: 40px;
  margin-bottom: 20px;
}


.cm_search input {
  border-color: #E1E2E7;
  border-radius: 0;
  padding-left: 50px;
}

.cm_search {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
}
.post_rel{
  position: relative;
}
.search_icon {
  position: absolute;
  width: 18px;
  top: 9px;
  right: 10px;
}

.min_breadcurm p {
  margin: 0;
}

.cm_folder_list {
  margin-top: 30px;
}

.folder_wrap {
  display: inline-block;
  text-align: center;
  margin-bottom: 30px;
  padding: 10px;
  cursor: pointer;
  min-width: 180px;
}

.folder_wrap:hover {
  background: #f8f8f8;
}

.folder_icon {
  display: inline-block;
  width: 75px;
}

.folder_wrap h3 {
  font-size: 18px;
  margin: 0;
  margin-top: 10px;
}
.more_dropdown_btn ul.profile_menu li a , .more_dropdown_btn ul.profile_menu li a:hover{
  padding: 4px;
  display: block;
  /* padding-left: 26px; */
  padding-left: 20px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  color: #818BA0;
  font-size: 12px;
  background:none;
}
input.form-control {
  padding-right: 32px;
}
.form-group a{
  cursor: pointer;
}
.more_dots .dropdown-menu {
  padding: 0rem;
}
.more_dots .dropdown-menu ul.profile_menu li span p {
  padding: 0;
  font-size: 12px;
}
.more_dropdown_btn ul.profile_menu li:last-child a {
  border-bottom: none;
}
.header_dropdown_btn.more_dropdown_btn.show.dropdown .dropdown-menu.show {
  transform: translate3d(16px, 24px, 0px) !important;
}
/* ----------------------toggle btn--------------------------------- */
.example .btn-toggle {
  top: 50%;
  transform: translateY(-50%);
}
.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}
.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
    width: 4rem;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
    font-size: 12px;
}
.btn-toggle:before {
  content: 'Inactive';
  left: -4rem;
}
.btn-toggle:after {
  content: 'active';
  right: -3.5rem;
  opacity: 0.5;
}
.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.btn-toggle.active:before {
  opacity: 0.5;
}
.btn-toggle.active:after {
  opacity: 1;
}
.btn-toggle.active {
  background-color: #11ff0d;
}
/* ---------------------------------media structure---------------------- */
.media_parent {
  display: flex;
  flex-wrap: wrap;
  margin-top:40px;
}
button.close:focus {
  outline: none;
}
.media_container {
  border: 1px solid #E7E7EA;
  margin: 6px;
  width: 219px;
  height: 246px;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.media_container.center {
 padding: 20px;
}
.media_container .center {
  width: 100%;
}
.body_header.folder h6 {
  margin-bottom: 0;
}
.media_container p {
  margin-bottom: 0px;
  padding: 12px 0px;
  font-weight: 600;
}
.embed-responsive img {
  width: 100%;
  padding: 12px;
  height: 180px;
  object-fit: cover;
}
.media.center {
  top: 23%;
}
.media_modal .modal-content {
  background: #fff;
}
.embed-responsive-16by9::before{
  padding-top: 0px;
}
.media_modal .modal-dialog {
  max-width: 70%;
}
.media_parent{
  display: flex;
  

}
.img_div {
    width: 56%;
    margin-right: 4%;
    position: relative;
    min-height: 550px;
    object-fit: cover;
    max-height: 550px;
}
/* ---------------------------video------------------------- */
video.video-react-video {
  object-fit: cover;
  min-height: 550px;
  max-height: 550px;
}
.video-react .video-react-big-play-button.video-react-big-play-button-center {
  /* top: 38% !important;
  left: 50% !important;
  position: absolute;
  transform: translate(-6%, -199%) !important; */
  display: none;
}
.video-react-has-started .video-react-control-bar {
  display: flex;
  visibility: hidden !important;
}
/* 
.carousel_btn {
  top: 38% !important;
} */
.video-react-controls-enabled.video-react-paused.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react {
  padding-top: 56% !important
}
.carousel_btn{
  position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100%;
}
.carousel-control i{
  padding: 10px 12px;
    border: 2px solid #5a5a5a;
    border-radius: 100px;
    font-size: 18px;
    color: #5a5a5a;
}
.left.carousel-control{
  float: left;
  cursor: pointer;
  margin-left: 20px;
}
.right.carousel-control{
  float: right;
  cursor: pointer;
  margin-right: 20px;
}
.carousel_btn .fa-play-circle {
  font-size: 45px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: #5b5b5b;
  cursor: pointer;
}
.desc_div {
  width: 40%;
}
.img_div img {
  /* width: 100%;
  height: 550px;
  object-fit:contain; */
  width: 100%;
    min-height: 550px;
    object-fit: cover;
    max-height: 550px;
}


.embed-responsive .img_heading{
  padding: 0 4px;
    width: 80%;
    margin-left: 10%;
    overflow: hidden;
}
.desc_div p {
  font-size: 30px;
  text-transform: capitalize;
}
.desc_div .icon_parent {
  display: flex;
}
.icon_parent .icon {
  text-align: center;
}
.icon_parent .icon i{
  border: 1px solid #747474;
    padding: 4px;
    border-radius: 4px;
    color: #747474;
}
.icon_parent .icon p{
  font-size: 16px;
    color: #747474;
    margin-top: 12px;
}
.icon_parent a:hover{
  text-decoration: none;
}
/* .desc_div .icon_parent{

} */
.desc_div .text {
  width: 100%;
  height: 426px;
  overflow-y: auto;
}
.desc_div .text p{
  font-size: 18px;
    white-space: normal;
    color: #616161;
    word-spacing: 4px;
}
ul.profile_menu.edit_menu li a, ul.profile_menu.edit_menu li span {
  padding: 8px 14px;
}
.profile_menu.edit_menu li span {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.profile_menu.edit_menu li span p {
  padding: 0;
  margin: 0;
}
/* --------------------------tab design---------------------- */
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link.active , .nav-tabs .nav-link:hover {
  color: #000000;
  background-color: #fff0;
  border-color: transparent;
  border-bottom: 3px solid #0D9CD8;
  font-weight: 600;
}
.nav-tabs .nav-link {
  color: #818BA0;
  font-weight: 600;
}
.tab-content {
  margin: 20px 0;
}
.btnTabs {
  position: absolute;
  right: 0px;
}
.cm_tab.nav.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.cm_tab.nav.nav-tabs a {
  padding: 14px 24px;
}
/* ---------------site---------------- */
.edit_record{
  float: right;
  font-size: 15px;
  cursor: pointer;
}
.edit_record_form .form-group p {
  font-weight: 600;
}
.btn_parent{
  position: relative;
}
.btn_child{
  position: absolute;
    right: 0px;
    bottom: 32px;
}
.edit_pencil{
  font-size: 20px;
    color: #33385d;
}
.management_edit {
  word-wrap: break-word;
  white-space: normal;
}
/* --------------------------dashboard-------------------------- */
.dynamic_record {
  min-height: 120px;
  box-shadow: 0px 9px 18px #ebebeb;
  -webkit-box-shadow: 0px 9px 18px #ebebeb;  
  -moz-box-shadow:   0px 9px 18px #ebebeb;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 81px 97px;
    margin-bottom: 40px;
}
.dynamic_record  .icon {
  width: 80px;
  height: 80px;
  background: #54C7FC;
  position: relative;
  border-radius: 100px;
  /* box-shadow: 0px 2px 21px #54c7fc7d; */
}
.fa_calender{
  position: absolute;
    top: 43px;
    right: 20px;
}
.form-group.pos_rel .react-datepicker-wrapper {
  width: 100%;
}
.graph_color_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.graph_color_circle div {
  display: flex;
  padding: 0 12px;
  align-items: center;
}
.graph_color_circle div i {
  font-size: 25px;
  color: #54C7FC;
  padding: 0px 12px;
}
.graph_color_circle div span {
  font-size: 12px;
  font-weight: 700;
}
.circle_box{
  width: 18px;
    height: 18px;
    background: #03A9F4;
    border-radius: 100px;
}
.dynamic_record .icon svg{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50% , -50%);
  width: 27px;
}
.site_bg{
  background:#FFCD00 !important;
  box-shadow: 0px 2px 21px #ffcd0094;
  -webkit-box-shadow:0px 2px 21px #ffcd0094;  
  -moz-box-shadow:   0px 2px 21px #ffcd0094;
}
.user_bg{
  background: #44DB5E !important;
  box-shadow: 0px 2px 21px #44db5e8f;
  -webkit-box-shadow:0px 2px 21px #44db5e8f;
  -moz-box-shadow:  0px 2px 21px #44db5e8f;
}
.cummunity_bg{
  background: #0076FF !important;
  box-shadow: 0px 2px 21px #0076ff96;
  -webkit-box-shadow:0px 2px 21px #0076ff96; 
  -moz-box-shadow:0px 2px 21px #0076ff96;
}
.project_bg{
  background: #54C7FC !important;
  box-shadow: 0px 2px 21px #54c7fc7d;
  -webkit-box-shadow:0px 2px 21px #54c7fc7d;  
  -moz-box-shadow: 0px 2px 21px #54c7fc7d;
}
.text_right h5{
  color:#54C7FC
}
.user_text{
  color:#FFCD00 !important;
}
.site_text{
  color: #44DB5E !important;
  margin-bottom: 2px;
}
.cummunity_text{
  color: #0076FF !important;
}
.graph_box{
    min-height: 450px;
    box-shadow: 0px 9px 18px #ebebeb;
    -webkit-box-shadow: 0px 9px 18px #ebebeb;  
    -moz-box-shadow:   0px 9px 18px #ebebeb;
    margin-top: 40px;
    padding: 25px;
}
.text_left h4 {
  margin-bottom: 0;
  font-weight: 600;
}
.graph_box h5 {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.inner_heading {
  font-weight: 600;
}
.pos_rel{
  position: relative;
}

/* ----------------custom file upload css-------------------- */
.rct-title {
  padding: 0 5px;
  color: #000;
  font-weight: 500;
}
.custom-file-input {
  height: calc(2.1em + .75rem + 2px);
}
.custom-file-label::after{
  padding: .5rem 1.5rem;
    color: #fff;
    font-weight: 500;
    background-color: #27a3dc;
    height: calc(1.82em + .75rem + 2px);
    font-size: 15px;
}
.custom-file-label{
padding: .6rem .75rem;
height: calc(2.1em + .75rem + 2px) !important;
}

/* .custom-file  {
  margin: 16px 0 18px;

} */
.custom-file  {
  margin: 3px 0 8px;

}
.custom-file-label {
  overflow: hidden;
}
/* ------------------css for ant dropdown--------------- */
/* select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
} */
select {
  appearance: none;
  -moz-appearance: none;
-webkit-appearance: none;
	background-image:url("assets/img/carret.png");
  background-position:
  calc(100% - 10px) calc(1em + 4px),
  calc(100% - 15px) calc(1em + 2px),
  100% 0;
 -moz-appearance: none;
-webkit-appearance: none;
  background-repeat: no-repeat;
}
select + i.fa {
  float: right;
  margin-top: -30px;
  margin-right: 5px;
  pointer-events: none;
  background-color: #fff;
  padding-right: 5px;
  font-size: 12px;
    color: #595959;
}
.dropdown_flex {
  display: flex;
    margin: 5px;
    align-items: center;
}
.dropdown_flex div:first-child {
  width: 20%;
  height: 30px;
}
.dropdown_flex img {
  border-radius: 6px;
  margin: 0px !important;
  width: 100%;
  object-fit: contain;
}
.dropdown_flex div:nth-child(2) p {
  margin-bottom: 0;
  line-height: 1.7;
}
.dropdown_flex div:nth-child(2) {
  text-align: left;
  margin-left: 0px;
  font-weight: 600;
}
.dropdown_flex img{
    border-radius: 6px;
    margin: 0px !important;
    width: 65%;
}
.ant-select-selection {
  min-height: calc(2.1em + .75rem + 2px);
}

.arrow .ant-select-selection:after {
  content: '\f078';
  font: normal normal normal 17px/1 FontAwesome;
  color: #0ebeff;
  right: 11px;
  top: 6px;
  /* height: 34px; */
padding: 8px 0px 0px 8px;
  border-left: 0px solid #0ebeff;
  position: absolute;
  pointer-events: none;
  font-size: 12px;
  color: #595959;
}
span.ant-select-arrow {
  display: none;
}
.cm_map_wrapper {
  white-space: normal;
  word-break: break-all;
}
.sidenav a.active svg g path , .sidenav a.active svg path {
  fill: #6ECEEA !important;
}
.rct-node-icon {
  display: none !important;
}
/* .custom-file-input:focus~.custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
  border: 1px;
} */
.table-responsive {
  min-height: 622px;
}
td {
  /* display: table-cell; */
  vertical-align: middle !important;
}
/* -------------------for view_mode--------------- */
.view_mode p{
  font-size: 18px;
  font-weight: 600;
}
.accordion input.form-control {
  margin-left: 10px;
}
.fade.custom_modal.treeview_modal.modal.show .modal-dialog {
  max-width: 660px;
}
button.btn.btn-primary.btn-block {
  border-radius: 4px;
}

@keyframes slide_right {
  to   {left: 20px;}
}


div#map {
  width: 100%;
  height: calc(100vh - 260px);
}

/* --------------------------------------shifter--0------------------------ */
.shifter_table tbody tr td a {
  color: #0D9CD8;
  text-decoration: none;
}
.shifter_table tbody tr td {
  font-weight: 600;
}
.view_pdf_popup .btn{
min-width: 126px;
}
.view_pdf_container{
  width: 100%;
    min-height: 450px;
    border: 1px solid black;
    object-fit: contain;
}
/* --------------step process------------ */
.steps {
  width: 70%;
  padding: 0 20px;
  margin: 60px auto;
  box-sizing: border-box;
  /* End #steps ul*/
}
.steps ul,
#steps li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.steps ul {
  display: table;
  width: 100%;
  /* End #steps li*/
}
.steps ul li {
  display: table-cell;
  position: relative;
  /* End .step*/
}
.steps ul li:first-child {
  width: 1px;
}
.steps ul li:first-child .step:before {
  content: none;
}
.steps ul li .step {
  width: 40px;
    height: 40px;
    border: 1px solid;
    border-radius: 50%;
    border-color: #dedede;
    line-height: 37px;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    background-color: #ffffff;
    float: right;
}
.steps ul li .step:before {
  height: 1px;
  display: block;
  background-color: #dedede;
  position: absolute;
  content: '';
  right: 40px;
  left: 0px;
  top: 50%;
  cursor: default;
}
.steps ul li .step:after {
  display: block;
  transform: translate(-42px, 10px);
  color: #E1E2E7;
  content: attr(data-desc);
  font-size: 13px;
  line-height: 15px;
  min-width: 120px;
}
.steps ul li .step.active {
  border-color: #ffffff;
  color: #6fcfeb;
  background: #ffffff;
  box-shadow: 0px 4px 7px #c6c6c6;
}
.steps ul li .step.active:before {
  background: linear-gradient(to right, #6fcfeb 0%, #dedede 100%);
}
.steps ul li .step.active:after {
  color: #292929;
  /* font-weight: 600; */
}
.steps ul li .step.done:after {
  color: #292929;
}
.steps ul li .step.done {
  background: #6fcfeb;
  border-color: #6fcfeb;
  color: #fff;
  box-shadow: 0px 4px 7px #c6c6c6;
}
.step.done i{
  color:#fff;
}
/*--------------step process end------------*/
.upload_parent {
  border: 1px solid #E1E2E7;
  min-height: 400px;
  padding: 86px 30px;
  text-align: center;
  border-radius: 6px;
  margin: 88px auto;
  width: 600px;
}
.upload_parent p {
  color: #888;
  margin: 26px 0;
}
.upload_parent h6 {
  font-weight: 600;
  margin-bottom: 26px;
}
.upload_btn {
  padding: 12px 45px;
  opacity: 0.6;
}
.btn-shifter , .btn-shifter:hover {
  /* background: #27a3dc; */
  min-width: 140px;
  border-radius: 4px;
  /* color: #fff; */
}
/*-----------------ApplySiftRulebook---------------*/
.rulebook h6 {
    font-size: 13px;
    font-weight: 600;
    padding: 5px 1px;
}
.rulebook_wrapper {
  border-radius: 6px;
  border: 1px solid #E1E2E7;
}
.rulebook_wrapper .search {
  border: none;
  padding: 0 33px;
}
.rulebook_wrapper ul hr {
  margin-top: 0px;
}
.rulebook_wrapper ul {
  padding-inline-start: 10px;
    padding-inline-end: 10px;
    list-style-type: none;
    max-height: 480px;
    overflow: auto;
}
.rulebook_wrapper ul li {
  padding: 12px 0px;
  font-weight: 600;
}
.rulebook_wrapper .search_icon {
  top: 11px;
  left: 10px;
}
.rulebook_wrapper ul li span {
  float: right;
  margin-top: -3px;
  cursor: pointer;
}
.rulebook_wrapper ul li span svg {
  width: 20px;
}
.rulebook_wrapper ul li:nth-child(2) {
  padding-top: 0px;
}
.checkmark {
    width: 20px;
    height: 20px;
    background: #6fcfeb;
    border-radius: 20px;
    text-align: center;
    color: #fff;
    position: relative;
    box-shadow: 0px 3px 6px #888;
}
.rulebook_wrapper ul li span.checkmark svg {
  width: 11px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
}
.checkmark.minus {
  background: #FF1F70;
  box-shadow: 0px 3px 6px #d1d1d1;
}
.ant-select-selection-selected-value {
  width: 100%;
}

/* ----------------------------custom treeview-------------------------*/
.accordion>.card {
  border: none;
  border-radius: 0;
}
.cm_accordion_header.card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: none !important;
}
.cm_accordion_header.card-header .btn ,.cm_accordion_header.card-header .btn:hover{
  padding: 4px 6px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color:#000
}
.cm_accordion_header.card-header .btn i {
  /* / margin-right: 8px;  */
  color: #a3a5a6;
  font-size: 12px;
  position: absolute;
  margin-left: -4px;
}
.cm_accordion_header.card-header .btn input[type="checkbox"] {
  margin: 0px 10px;
}
.cm_accordion_body.card-body {
  padding: 0px 0px 0px 46px;
}
.cm_accordion_body.card-body input[type="checkbox"] {
  margin-right: 6px;
}
@media (min-width:320px) and (max-width:480px){
  /* ------------for small---------- */
.dynamic_record {
justify-content: space-between;
align-items: center;
}
.text_right p, text_left p {
font-size: 12px;
}
.text_right h5 {
font-size: 14px;
}
.text_left h4 {
font-size: 20px;
}
.dynamic_record .icon {
width: 70px;
height: 70px;
background: #54C7FC;
position: relative;
border-radius: 100px;
}
}

@media(min-width: 1620px){
  .xl_container {
      max-width: 1580px;
  }
}

@media(max-width: 991px){
  .auth_body_wrapper {
    padding: 60px 0;
    min-height: 100vh;
  }

  .auth_content {
    padding: 30px 15px;
    padding-top: 100px;
  }

  .cm_login_form {
    width: 100%;
    padding: 0 15px;
  }

  .login_logo {
    margin-bottom: 60px;
    margin-top: 40px;
  }
  .table tbody tr td {
    border: none;
    white-space: nowrap;
}
  .create_acc_btn {
    margin-top: 50px;
  }
  

}
@media(max-width: 1024px){
  .menuRespo{
    display: none;
    position: relative;
  }
  .menu_open .menuRespo{
    display: block;
    position: relative;
  }
  body.menu_open {
    position: fixed;
    overflow: hidden;
  }
  /* -------------------- */
  .main-body {
    margin-left: 0;
    padding: 30px;
}

.mobile_trigger .cross {
    display: none;
}
.mobile_trigger {
    display: block;
    position: absolute;
    font-size: 28px;
    left: 20px;
    top: 10px;
    cursor: pointer;
    z-index: 9;
}
.menu_open .mobile_trigger .fa-bars {
    display: none;
}
.menu_open .mobile_trigger .cross {
    display: inline;
    font-family: monospace;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 246px;
}


}
@media(min-width: 1025px) and (max-width: 1504px)
{
  .dynamic_record {
    padding: 25px 15px;
    align-items: center;
  }
/* ----------for seprate----------- */
/* 
.dynamic_record {
    flex-wrap: wrap;
    text-align: center;
}

.dynamic_record div {
    width: 100%;
    text-align: center;
}
.dynamic_record .icon {
    border-radius: 100px;
    left: 50%;
    transform: translate(-50%);
    margin-bottom: 10px;
}
.dynamic_record .icon {
  width: 60px;
  height: 60px;
}
.dynamic_record .icon svg {
  width: 20px;
} */
}
@media(max-width: 575px){
  .auth_body_wrapper {
    padding: 0 0 !important;
    min-height: 100vh;
  }
}
@media (max-width:767px){
  
  .d-flex{
    flex-wrap: wrap;
   }
   .upload_parent{
     width: 100%;
   }
   .steps {
     width: 100%;
   }

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  ::i-block-chrome,.table tbody tr td {
    padding-bottom: 22px;
  }
  ::i-block-chrome, .table tbody tr{
    background-image: url('./assets/img/border-bottom.png');
    background-position: center bottom;
    background-repeat: repeat-x;
   
  }
  
}
@media (min-width:1276px) and (max-width:1450px){
  .cm_tab.nav.nav-tabs a {
    padding: 14px 14px;
}
}
/**loaderrrrrrr**/
.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(181, 181, 181, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}


.filter_text ul {
  position: absolute;
  background: #27a3dc;
  color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  text-align: left;
  width: 250px;
  right: 0;
  top: 23px;
  transform: translateY(-25px);
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
 
}
.filter_text {
  /* height: 32px;
  text-align: right;
  width: 100%;
  cursor: pointer;
  position: relative; */
  height: 32px;
    width: 100%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 99;
}

.filter_text.open ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}



.filter_text ul li {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 15px;
}




.filter_text ul:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #27a3dc;
  top: -6px;
  right: 5px;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.filter_text ul li:hover {
  background: #007bff;
}
.action_dropdown.dropdown-toggle, .filter_dropdown .dropdown-toggle{
    font-size: 12px;
    padding: 6px 22px;
}
.filter_dropdown1 .dropdown-toggle {
  background: #fff!important;
  border-color: #27a3dc !important;
  padding: 4px 14px;
  color: #27a3dc !important;
}
.action_dropdown {
  background-color: #33385d  !important;
  border-color: #33385d  !important;
}
/* .tagText{
  color: black
} */
.filter_dropdown .dropdown-toggle {
  background: white!important;
  border-color: #27a3dc !important;
  padding: 6px 14px;
  color: #27a3dc !important;
}
.filter_dropdown .dropdown-toggle svg {
  width: 18px;
}
.filter_dropdown .dropdown-toggle svg path {
  fill: #fff;
}
.filter_dropdown.dropdown {
  float: right;
  position: absolute;
  right: 0px;
}
.action_menu a.dropdown-item {
  font-size: 13px;
}
.action_menu a.dropdown-item {
  font-size: 13px;
}
.action_menu a.dropdown-item:hover{
  background-color: #464c740d;
 
}
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus , .action_dropdown.dropdown-toggle:focus{
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0) !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0) !important;  
  -moz-box-shadow:   0 0 0 0.2rem rgba(72, 180, 97, 0) !important;
}

@media (min-width:1200px) and (max-width:1400px){
  .dynamic_record {
    padding: 25px 8px;
}
.dynamic_record .icon {
    width: 60px;
    height: 60px;
}
.text_left h4 {
    font-size: 20px;
}
.text_left p {
    font-size: 12px;
}
.text_right h5 {
    font-size: 16px;
}
.text_right p {
    font-size: 12px;
}
}




/**************treeeeeeeeeeeeeee viewwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww****************/
/* Remove default bullets */
ul, #myUL {
  list-style-type: none;
}

#myUL {
  margin: 0;
  padding: 0;
}

.caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari */
  transform: rotate(90deg);  
}

.nested {
  display: none;
}
/* 
.active {
  display: block;
} */


/* input.form-control  {
  margin-left: 14px;
  padding: 7px;
  width: 228px;
 
} */

/*************************************************************************************/